// Полоса прокрутки
* {
  scrollbar: {
    width: thin;
    color: var(--color-primary) var(--color-gray-dark);
  }

  &::-webkit-scrollbar {
    @media(pointer: fine) {
      width: 16px;

      &-track {
        background-color: var(--color-gray-dark);

        &-piece {
          margin-block: 8px;
        }
      }

      &-thumb {
        background-color: var(--color-primary);

        border: {
          width : 4px;
          style : solid;
          color : var(--color-gray-dark);
          radius: 8px;
        }

        &:active {
          box-shadow: inset 0 8px 8px var(--color-black-50);
        }

      }

      &-corner {
        background-color: var(--color-gray-dark);
      }

      &-button {
        background-color: var(--color-primary);

        &:vertical {
          &:decrement:start {
            background-image: linear-gradient(120deg, var(--color-gray-dark) 35%, var(--color-none) 41%),
              linear-gradient(240deg, var(--color-gray-dark) 35%, var(--color-none) 41%),
              linear-gradient(0deg, var(--color-gray-dark) 35%, var(--color-none) 41%);
          }

          &:increment:end {
            background-image: linear-gradient(300deg, var(--color-gray-dark) 35%, var(--color-none) 41%),
              linear-gradient(60deg, var(--color-gray-dark) 35%, var(--color-none) 41%),
              linear-gradient(180deg, var(--color-gray-dark) 35%, var(--color-none) 41%);
          }

          &:increment:start,
          &:decrement:end {
            display: none;
          }
        }

        &:horizontal {
          &:decrement:start {
            background-image: linear-gradient(30deg, var(--color-gray-dark) 35%, var(--color-none) 41%),
              linear-gradient(150deg, var(--color-gray-dark) 35%, var(--color-none) 41%),
              linear-gradient(-90deg, var(--color-gray-dark) 35%, var(--color-none) 41%);
          }

          &:increment:end {
            background-image: linear-gradient(210deg, var(--color-gray-dark) 35%, var(--color-none) 41%),
              linear-gradient(330deg, var(--color-gray-dark) 35%, var(--color-none) 41%),
              linear-gradient(90deg, var(--color-gray-dark) 35%, var(--color-none) 41%);
          }

          &:increment:start,
          &:decrement:end {
            display: none;
          }
        }
      }
    }

    @media(hover) {

      &-thumb:hover,
      &-button:vertical:hover,
      &-button:horizontal:hover {
        background-color: var(--color-primary-dark);
      }
    }
  }
}