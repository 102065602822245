// Поле
.input {
  flex-grow       : 1;
  width           : 100%;
  height          : var(--size-lg);
  background-color: var(--color-white);
  font-size       : var(--text-16);
  padding         : 12px 16px;
  border          : 1px solid var(--color-grey);
  transition      : 0.2s ease;
  user-select     : initial;

  &-cover {
    display: flex;
    width  : 100%;
  }

  &--textarea {
    height: auto;
  }

  &--error {
    border-color: var(--color-red);
  }

  &:first-of-type {
    border: {
      top-left-radius   : 16px;
      bottom-left-radius: 16px;
    }
  }

  &:last-of-type {
    border: {
      top-right-radius   : 16px;
      bottom-right-radius: 16px;
    }
  }

  &:not(&--error):focus {
    border-color: var(--color-text);
  }

  &::placeholder {
    color: var(--color-grey);
  }

  &:disabled {
    pointer-events: none;
    opacity       : 0.5;
  }
}