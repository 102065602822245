// Форма
.form {
  display       : flex;
  flex-direction: column;

  &-label {
    display       : flex;
    flex-direction: column;
  }

  &-wrapper {
    display : block;
    position: relative;
    width   : 100%;
  }

  &-error {
    display         : flex;
    align-items     : center;
    justify-content : center;
    text-align      : center;
    position        : absolute;
    left            : 10px;
    right           : 10px;
    bottom          : -16px;
    height          : 16px;
    font-size       : var(--text-12);
    color           : var(--color-white);
    background-color: var(--color-red);
    border-radius   : 12px;
  }
}