// Montserrat
@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-ExtraLight.eot");
  src        : local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"),
    url("../fonts/Montserrat/Montserrat-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-ExtraLight.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-ExtraLight.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style : normal;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-MediumItalic.eot");
  src        : local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"),
    url("../fonts/Montserrat/Montserrat-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-MediumItalic.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-MediumItalic.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style : italic;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-Italic.eot");
  src        : local("Montserrat Italic"), local("Montserrat-Italic"),
    url("../fonts/Montserrat/Montserrat-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Italic.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Italic.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style : italic;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-Regular.eot");
  src        : local("Montserrat Regular"), local("Montserrat-Regular"),
    url("../fonts/Montserrat/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style : normal;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-ThinItalic.eot");
  src        : local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"),
    url("../fonts/Montserrat/Montserrat-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-ThinItalic.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-ThinItalic.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style : italic;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-Bold.eot");
  src        : local("Montserrat Bold"), local("Montserrat-Bold"),
    url("../fonts/Montserrat/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style : normal;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-Light.eot");
  src        : local("Montserrat Light"), local("Montserrat-Light"),
    url("../fonts/Montserrat/Montserrat-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Light.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Light.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style : normal;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-Black.eot");
  src        : local("Montserrat Black"), local("Montserrat-Black"),
    url("../fonts/Montserrat/Montserrat-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Black.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Black.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style : normal;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-Medium.eot");
  src        : local("Montserrat Medium"), local("Montserrat-Medium"),
    url("../fonts/Montserrat/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Medium.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Medium.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style : normal;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-ExtraLightItalic.eot");
  src        : local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"),
    url("../fonts/Montserrat/Montserrat-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-ExtraLightItalic.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-ExtraLightItalic.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style : italic;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-BlackItalic.eot");
  src        : local("Montserrat Black Italic"), local("Montserrat-BlackItalic"),
    url("../fonts/Montserrat/Montserrat-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-BlackItalic.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-BlackItalic.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style : italic;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-ExtraBoldItalic.eot");
  src        : local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"),
    url("../fonts/Montserrat/Montserrat-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-ExtraBoldItalic.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style : italic;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-SemiBoldItalic.eot");
  src        : local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"),
    url("../fonts/Montserrat/Montserrat-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-SemiBoldItalic.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style : italic;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-ExtraBold.eot");
  src        : local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("../fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-ExtraBold.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-ExtraBold.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style : normal;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-BoldItalic.eot");
  src        : local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"),
    url("../fonts/Montserrat/Montserrat-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-BoldItalic.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-BoldItalic.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style : italic;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-LightItalic.eot");
  src        : local("Montserrat Light Italic"), local("Montserrat-LightItalic"),
    url("../fonts/Montserrat/Montserrat-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-LightItalic.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-LightItalic.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style : italic;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-SemiBold.eot");
  src        : local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("../fonts/Montserrat/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style : normal;
}

@font-face {
  font-family: "Montserrat";
  src        : url("../fonts/Montserrat/Montserrat-Thin.eot");
  src        : local("Montserrat Thin"), local("Montserrat-Thin"),
    url("../fonts/Montserrat/Montserrat-Thin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat/Montserrat-Thin.woff2") format("woff2"),
    url("../fonts/Montserrat/Montserrat-Thin.woff") format("woff"),
    url("../fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style : normal;
}

// Inter
@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-BoldItalic.eot");
  src        : local("Inter Bold Italic"), local("Inter-BoldItalic"),
    url("../fonts/Inter/Inter-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-BoldItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-BoldItalic.woff") format("woff"),
    url("../fonts/Inter/Inter-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style : italic;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-MediumItalic.eot");
  src        : local("Inter Medium Italic"), local("Inter-MediumItalic"),
    url("../fonts/Inter/Inter-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-MediumItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-MediumItalic.woff") format("woff"),
    url("../fonts/Inter/Inter-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style : italic;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-ThinItalicBETA.eot");
  src        : local("Inter Thin Italic BETA"), local("Inter-ThinItalicBETA"),
    url("../fonts/Inter/Inter-ThinItalicBETA.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-ThinItalicBETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ThinItalicBETA.woff") format("woff"),
    url("../fonts/Inter/Inter-ThinItalicBETA.ttf") format("truetype");
  font-weight: 100;
  font-style : italic;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-Regular.eot");
  src        : local("Inter Regular"), local("Inter-Regular"),
    url("../fonts/Inter/Inter-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Regular.woff") format("woff"),
    url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style : normal;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-Black.eot");
  src        : local("Inter Black"), local("Inter-Black"),
    url("../fonts/Inter/Inter-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-Black.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Black.woff") format("woff"),
    url("../fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style : normal;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-BlackItalic.eot");
  src        : local("Inter Black Italic"), local("Inter-BlackItalic"),
    url("../fonts/Inter/Inter-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-BlackItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-BlackItalic.woff") format("woff"),
    url("../fonts/Inter/Inter-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style : italic;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-LightBETA.eot");
  src        : local("Inter Light BETA"), local("Inter-LightBETA"),
    url("../fonts/Inter/Inter-LightBETA.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-LightBETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-LightBETA.woff") format("woff"),
    url("../fonts/Inter/Inter-LightBETA.ttf") format("truetype");
  font-weight: 300;
  font-style : normal;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-SemiBold.eot");
  src        : local("Inter Semi Bold"), local("Inter-SemiBold"),
    url("../fonts/Inter/Inter-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/Inter/Inter-SemiBold.woff") format("woff"),
    url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style : normal;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-ExtraBoldItalic.eot");
  src        : local("Inter Extra Bold Italic"), local("Inter-ExtraBoldItalic"),
    url("../fonts/Inter/Inter-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ExtraBoldItalic.woff") format("woff"),
    url("../fonts/Inter/Inter-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style : italic;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-Bold.eot");
  src        : local("Inter Bold"), local("Inter-Bold"),
    url("../fonts/Inter/Inter-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-Bold.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Bold.woff") format("woff"),
    url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style : normal;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-ExtraLightItalicBETA.eot");
  src        : local("Inter Extra Light Italic BETA"), local("Inter-ExtraLightItalicBETA"),
    url("../fonts/Inter/Inter-ExtraLightItalicBETA.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-ExtraLightItalicBETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ExtraLightItalicBETA.woff") format("woff"),
    url("../fonts/Inter/Inter-ExtraLightItalicBETA.ttf") format("truetype");
  font-weight: 200;
  font-style : italic;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-ExtraLightBETA.eot");
  src        : local("Inter Extra Light BETA"), local("Inter-ExtraLightBETA"),
    url("../fonts/Inter/Inter-ExtraLightBETA.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-ExtraLightBETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ExtraLightBETA.woff") format("woff"),
    url("../fonts/Inter/Inter-ExtraLightBETA.ttf") format("truetype");
  font-weight: 200;
  font-style : normal;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-ExtraBold.eot");
  src        : local("Inter Extra Bold"), local("Inter-ExtraBold"),
    url("../fonts/Inter/Inter-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-ExtraBold.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ExtraBold.woff") format("woff"),
    url("../fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style : normal;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-ThinBETA.eot");
  src        : local("Inter Thin BETA"), local("Inter-ThinBETA"),
    url("../fonts/Inter/Inter-ThinBETA.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-ThinBETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-ThinBETA.woff") format("woff"),
    url("../fonts/Inter/Inter-ThinBETA.ttf") format("truetype");
  font-weight: 100;
  font-style : normal;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-LightItalicBETA.eot");
  src        : local("Inter Light Italic BETA"), local("Inter-LightItalicBETA"),
    url("../fonts/Inter/Inter-LightItalicBETA.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-LightItalicBETA.woff2") format("woff2"),
    url("../fonts/Inter/Inter-LightItalicBETA.woff") format("woff"),
    url("../fonts/Inter/Inter-LightItalicBETA.ttf") format("truetype");
  font-weight: 300;
  font-style : italic;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-Italic.eot");
  src        : local("Inter Italic"), local("Inter-Italic"),
    url("../fonts/Inter/Inter-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-Italic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Italic.woff") format("woff"),
    url("../fonts/Inter/Inter-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style : italic;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-SemiBoldItalic.eot");
  src        : local("Inter Semi Bold Italic"), local("Inter-SemiBoldItalic"),
    url("../fonts/Inter/Inter-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/Inter/Inter-SemiBoldItalic.woff") format("woff"),
    url("../fonts/Inter/Inter-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style : italic;
}

@font-face {
  font-family: "Inter";
  src        : url("../fonts/Inter/Inter-Medium.eot");
  src        : local("Inter Medium"), local("Inter-Medium"),
    url("../fonts/Inter/Inter-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Inter/Inter-Medium.woff2") format("woff2"),
    url("../fonts/Inter/Inter-Medium.woff") format("woff"),
    url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style : normal;
}