// Главный блок
.main {
  min-height : 1080px;
  padding-top: 215px;

  &__info {
    background: linear-gradient(0deg, #22121C, #22121C), radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 73.05%);
    border-radius: 100px;
    transform: rotate(-6deg);
  }

  &__light {
    mix-blend-mode: hard-light;
    animation: 3s events linear infinite both;
  }

  &__fade {
    mix-blend-mode: luminosity;
  }

  &__blur-red {
    left: auto;
    right: -70%;
    top: 20%;
  }

  &__pack {
    max-width: 1560px;
  }

  &__blur {
    width: 54px;
    height: 54px;
    filter   : blur(40px);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__bg {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 670px;
      background: linear-gradient(180deg, rgba(5, 1, 2, 0.00) 0%, #050102 96.87%);
    }
  }

  &__banner {
    margin-top: 220px;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid #FFFFFF33;
    box-shadow: 0 4px 40px 0 #C36D2E33;
    backdrop-filter : blur(30px);
    max-width: 1024px;
  }

  &__picture {
    max-width: 344px;
  }

  &__gradient {
    inset: 0;
    background-color: var(--color-black-50);
  }

  &__play {
    width: 70px;
    height: 70px;
  }

  &__fire {
    animation: fire 3s linear infinite;
  }

  &__circle {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  &__burger {
    left : 20%;
    bottom: 100px;

    & .icon {
      animation: parallax 3s linear infinite;
    }
  }

  &__tree-red {
    left : 30%;
    bottom: 50%;

    & .icon {
      animation: parallax 3s linear infinite;
    }
  }

  &__star {
    right : 40%;
    top: 40%;

    & .icon {
      animation: parallax 3s linear infinite;
    }
  }

  &__tree-yellow {
    right : 20%;
    bottom: 300px;

    & .icon {
      animation: parallax 3s linear infinite;
    }
  }

  @media(max-width: $xl) {
    & {
      min-height: 900px;
    }

    &__bg {
      &::after {
        height: 300px;
      }
    }

    &__pack {
      max-width: 1000px;
    }

    &__banner {
      margin-top: 120px;
    }
  }

  @media(max-width: $lg) {
    & {
      min-height: 700px;
    }

    &__pack {
      max-width: 750px;
    }
  }

  @media(max-width: $md) {
    & {
      padding-top: 130px;
    }

    &__bg {
      &::after {
        height: 130px;
      }
    }

    &__banner {
      margin-top: 60px;
      flex-direction: column;
      align-items: stretch;
    }
  }

  @media(max-width: $sm) {
    & {
      min-height: 980px;
    }

    &__picture {
      max-width: 100%;
    }
  }

  @media(min-width: 2000px) {
    &__bg {
      width: 1920px;
      margin-inline: auto;
    }
  }
}

// Квиз
.quiz {
  margin-top: -100px;

  &__form {
    background-image: radial-gradient(98.48% 326.98% at 0% 3.35%, rgba(40, 40, 40, 0.8) 0%, rgba(28, 28, 28, 0.4) 100%);
    backdrop-filter : blur(15px);
  }

  &-slider {
    max-width: 460px;

    &[data-quiz="stop"] .swiper-button-next {
      pointer-events: none;
      opacity       : 0.5;
    }

    &[data-quiz-end] &-bottom {
      display: none;
    }

    &[data-quiz-end] &-submit {
      display: flex;
    }

    &__btn {
      left : 0;
      right: 0;
    }
  }

  &__btn {
    border-color: var(--color-white-30);
  }

  &__radio:checked+&__btn {
    color           : var(--color-text);
    background-color: var(--color-white);
  }

  &-images {
    max-width: 570px;
  }

  &__pack {
    &::before {
      padding-top: 90%;
    }
  }

  @media(max-width: $xl) {
    & {
      margin-top: 0;
    }

    &-images {
      max-width: 400px;
    }
  }

  @media(max-width: $lg) {
    &-slider {
      max-width: 100%;
    }
  }

  @media(max-width: $sm) {
    & {
      margin-top: -150px;
    }
  }
}

// Концерт
.concert {
  &__tree-red {
    left: 40%;
    top : 420px;

    & .icon {
      animation: parallax 4s linear infinite;
    }
  }

  &__tree-yellow {
    right : 7%;
    bottom: 0;

    & .icon {
      animation: parallax 3s linear infinite;
    }
  }

  &__burger {
    right: 15%;
    top  : 0;

    & .icon {
      animation: parallax 5s linear infinite;
    }
  }

  &__pack {
    max-width  : 860px;
    margin-left: -150px;
  }

  &__blur {
    filter   : blur(100px);
    width    : 550px;
    height   : 110px;
    top      : 0;
    left     : -30%;
    animation: blur 3s linear infinite;
  }

  &__fire {
    animation: fire 3s linear infinite;
  }

  @media(max-width: $xl) {
    &__tree-red {
      left: 50%;
    }

    &__pack {
      max-width  : 600px;
      margin-left: -100px;
    }
  }

  @media(max-width: $lg) {
    &__tree-red {
      left: 10%;
    }

    &__block {
      align-items   : flex-start;
      flex-direction: column;
    }

    &__pack {
      max-width    : 100%;
      min-width: 450px;
      margin-left  : 0;
      margin-bottom: -100px;
    }

    &__blur {
      left  : 0;
      width : 340px;
      height: 60px;
    }
  }

  @media(max-width: $md) {
    &__tree-red {
      top : 30%;
      left: 5%;
    }
  }

  @media(max-width: $sm) {
    &__pack {
      margin-bottom: -70px;
    }
  }
}

@keyframes fire {

  0%,
  100% {
    transform: scale(1.4);
    opacity  : 1;
  }

  50% {
    transform: scale(1);
    opacity  : 0.5;
  }
}

// Мероприятия
.events {

  &::before,
  &::after {
    content         : "";
    position        : absolute;
    top             : 0;
    bottom          : 0;
    width           : 350px;
    height          : 100%;
    background-image: linear-gradient(270deg, var(--color-text-90) 31.04%, var(--color-none) 100%);
    z-index         : 10;
  }

  &::before {
    left     : 0;
    transform: scaleX(-1);
  }

  &::after {
    right: 0;
  }

  &-wrapper {
    transition-timing-function: linear !important;
  }

  &__anim {
    animation: events 2s linear infinite;
  }

  &__item {
    animation: events 5s linear infinite;

    &--name {
      height       : 72px;
      border-radius: 100px;
    }

    &--akva {
      background-color: var(--color-akva-20);
    }

    &--green {
      background-color: var(--color-green-20);
    }

    &--pink {
      background-color: var(--color-pink-20);
    }
  }

  @media(max-width: $lg) {

    &::before,
    &::after {
      width: 90px;
    }
  }

  @media(max-width: $md) {
    &__item--name {
      height: 50px;
    }
  }
}

@keyframes events {

  0%,
  100% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(100deg);
  }
}

// Выступления
.performances {
  background: linear-gradient(180deg, var(--color-white-60) 0%, var(--color-none) 100%), #ECECEC;

  &-slide {
    max-width: 360px;
  }

  @media(min-width: $md) {
    &-slider--length &-slide {
      max-width     : 100%;
      flex-direction: row;
      align-items   : center;
    }

    &-slider--length &__pack {
      max-width   : 300px;
      margin-right: 30px;
    }
  }

  @media(min-width: $lg) {
    &-slider--length &__pack {
      max-width   : 420px;
      margin-right: 80px;
    }
  }

  @media(min-width: $xl) {
    &__btn {
      &--prev {
        left: -30px !important;
      }

      &--next {
        right: -30px !important;
      }
    }
  }

  @media(max-width: $sm) {
    &-slide {
      max-width: 100%;
    }
  }
}

// Репертуар
.repertoire {
  &-slider {
    max-width: 1920px;
  }

  &__card {
    max-width: 370px;
  }

  &__icon {
    margin-right : -35px;
    margin-bottom: -20px;
  }

  &__pack {
    max-width: 270px;
  }

  &__img {
    &--1 {
      top : 90px;
      left: 40px;
    }

    &--2 {
      top  : 40px;
      right: 36px;
    }
  }

  &__content {
    margin-inline: -40px;
    margin-top   : -60px;
    margin-bottom: -20px;
  }

  @media (hover) {
    &__card:hover &__img {
      visibility: visible;
      opacity   : 1;
    }

    &__card:hover &__pack {
      animation: record 5s linear infinite both;
    }
  }

  @media(max-width: $md) {
    &__icon {
      margin-right : -18px;
      margin-bottom: -8px;
    }

    &__content {
      margin-inline: -20px;
      margin-bottom: 0;
    }
  }

  @media(max-width: $sm) {
    &__card {
      max-width: 280px;
    }
  }
}

@keyframes record {
  100% {
    transform: rotate(360deg);
  }
}

// Клипы
.clips {
  &-slider {
    max-width: 1920px;
  }

  &__card {
    border-radius: 0;
  }

  &__play {
    inset: 0;
  }

  &__btn {
    width : 128px;
    height: 128px;
  }

  @media(max-width: $lg) {
    &__btn {
      width : 80px;
      height: 80px;
    }
  }
}

// Преимущества
.advantages {
  &__musician {
    left     : auto;
    bottom   : 0;
    max-width: 580px;
  }

  &__item {
    &--1 {
      background-image: radial-gradient(98.48% 326.98% at 0% 3.35%, rgba(40, 40, 40, 0.8) 0%, rgba(28, 28, 28, 0.4) 100%);
      backdrop-filter : blur(15px);
    }
  }

  &__record {
    max-width: 110px;
  }

  &__microphone {
    max-width: 159px;
    top      : 0;
    right    : 20px;
  }

  @media(min-width: $lg) {
    &__content {
      max-width: 700px;
    }

    &__title {
      text-align: left;
    }
  }

  @media(max-width: $xl) {
    &__musician {
      left: -150px;
    }
  }

  @media(max-width: $md) {
    &__microphone {
      max-width: 115px;
    }

    &__record {
      max-width   : 94px;
      margin-block: -16px;
    }

    &__item {
      &--3 {
        height: 150px;
      }
    }
  }
}

// Команда
.team {
  &__icon {
    margin-inline: -15px;
    margin-bottom: -20px;
  }

  &__card {
    background: linear-gradient(239.35deg, rgba(12, 5, 7, 0) 66.74%, #0C0507 90.66%), #0C0507;
  }

  &__pack {
    &::after {
      content   : "";
      position  : absolute;
      inset     : 0;
      background: linear-gradient(180deg, var(--color-none) 50.87%, var(--color-text-40) 100%);
      z-index   : 1;
    }
  }

  &__bg {
    background-image: linear-gradient(180deg, var(--color-none) 50.87%, var(--team-bg) 100%);

    &--primary {
      --team-bg: var(--color-primary-30);
    }

    &--purple {
      --team-bg: var(--color-purple-30);
    }

    &--sky {
      --team-bg: var(--color-sky-30);
    }

    &--red {
      --team-bg: var(--color-red-30);
    }

    &--akva {
      --team-bg: var(--color-akva-30);
    }

    &--blue {
      --team-bg: var(--color-blue-30);
    }
  }

  &__info {
    max-width: 310px;
    border-radius   : 100px;
    height          : 46px;
    background-color: var(--team-color);

    &--primary {
      --team-color: var(--color-primary-80);
    }

    &--green {
      --team-color: var(--color-green-80);
    }

    &--purple {
      --team-color: var(--color-purple-80);
    }

    &--sky {
      --team-color: var(--color-sky-80);
    }

    &--red {
      --team-color: var(--color-red-80);
    }

    &--akva {
      --team-color: var(--color-akva-80);
    }

    &--blue {
      --team-color: var(--color-blue-80);
    }
  }

  &__logo {
    bottom: -100px;
    right : -90px;
  }

  @media(min-width: $lg) {
    &-wrapper {
      transform: none !important;
    }

    &__person {
      max-width: 370px;
    }

    &__pack {
      height: 420px;
    }

    &__bg {
      &--sky {
        background-image: linear-gradient(224.25deg, var(--color-none) 60.84%, var(--color-primary-40) 97.51%);
      }
    }
  }

  @media(max-width: $lg) {
    &__logo {
      bottom: -45px;
    }
  }

  @media(max-width: $md) {
    &__icon {
      margin-inline: -10px;
      margin-bottom: -7px;
    }

    &__logo {
      bottom: -15px;
      right : 15px;
    }
  }
}

// Приглашение
.invitation {
  &__star {
    bottom: -200px;
    left  : 45%;
    filter: blur(4px);

    & .icon {
      animation: parallax 6s linear infinite;
    }
  }

  &__tree {
    top  : -800px;
    right: 10%;

    & .icon {
      animation: parallax 4s linear infinite;
    }
  }

  &__burger {
    top : -400px;
    left: 50%;

    & .icon {
      animation: parallax 7s linear infinite;
    }
  }

  &__feedback {
    background     : radial-gradient(98.48% 326.98% at 0% 3.35%, #282828 0%, rgba(28, 28, 28, 0.8) 100%);
    backdrop-filter: blur(15px);
  }

  @media(min-width: 2000px) {
    &__pack {
      margin-right: 20%;
    }
  }

  @media(min-width: $lg) {
    &__feedback {
      max-width: 500px;
    }

    &__pack {
      max-width: 1100px;
      right: -350px;
    }
  }

  @media(max-width: $xl) {
    &__pack {
      max-width: 900px;
    }

    &__tree {
      right: 5%;
    }

    &__burger {
      left: 5%;
      top : -300px;
    }
  }

  @media(max-width: $lg) {
    & {
      padding-bottom: 0;
    }

    &__tree {
      top: 0;
    }

    &__block {
      flex-direction: column;
      align-items   : flex-start;
      max-width     : 500px;
    }

    &__bg {
      position: static;
      margin-inline: -15px;
    }

    &__pack {
      max-width: 100%;
    }
  }

  @media(max-width: $sm) {
    &__star {
      left: 80%;
    }

    &__burger {
      top: -500px;
    }
  }
}

// Вопросы
.questions {
  &__circle {
    min-width: 20px;
    width    : 20px;
    height   : 20px;
    border   : 2px solid var(--color-primary);
  }

  &__arrow {
    transform: rotate(180deg);
  }

  &__accordion[data-accordion="active"] &__logo {
    color: var(--color-primary);
  }

  &__accordion[data-accordion="active"] &__circle {
    background-color: var(--color-primary);
  }

  &__accordion[data-accordion="active"] &__arrow {
    color    : var(--color-white);
    transform: rotate(90deg);
  }
}

// Отзывы
.reviews {
  &-wrapper {
    transition-timing-function: linear !important;
  }

  &__card {
    max-width       : 370px;
    background-image: radial-gradient(98.48% 326.98% at 0% 3.35%, rgba(40, 40, 40, 0.8) 0%, rgba(28, 28, 28, 0.4) 100%);
    backdrop-filter : blur(15px)
  }

  @media(max-width: $md) {
    &__card {
      max-width: 310px;
    }
  }
}

// Знакомство
.acquaint {
  &__pack {
    max-width       : 600px;
    height          : 530px;
    background-image: radial-gradient(98.48% 326.98% at 0% 3.35%, var(--color-text) 0%, rgba(28, 28, 28, 0.88) 100%);
    backdrop-filter : blur(15px);
    overflow        : visible;
  }

  &__bg {
    transform: scaleY(-1);
  }

  &__image {
    transform: scale(1.2) translateY(-24px);
  }

  &__tree {
    left  : 10%;
    bottom: -550px;

    & .icon {
      animation: parallax 4s linear infinite;
    }
  }

  &__lightning {
    top : -10%;
    left: 65%;

    & .icon {
      animation: lightning 3s linear infinite, events 5s linear infinite;
    }
  }

  &__burger {
    top  : -700px;
    right: 5%;

    & .icon {
      animation: parallax 6s linear infinite;
    }
  }

  @media(min-width: $lg) {
    &__feedback {
      max-width: 500px;
    }
  }

  @media(max-width: $xl) {
    &__pack {
      height: 517px;
    }
  }

  @media(max-width: $lg) {
    &__block {
      max-width: 500px;
    }
  }

  @media(max-width: $sm) {
    & {
      padding-top: 0;
    }
  }
}

@keyframes lightning {

  0%,
  100% {
    filter: hue-rotate(0);
  }

  50% {
    transform: scale(1.2);
  }
}

// Обратная связь
.feedback {
  &__block {
    max-width: 530px;
  }

  &__pack {
    max-width: 600px;
    height   : 530px;
  }

  @media(max-width: $xl) {
    & {
      width: 600px;
    }

    &__pack {
      display: none;
    }
  }
}

// Отправка
.submit {
  &__circle {
    width : 160px;
    height: 160px;
  }

  &__item {
    &--1 {
      top : 25%;
      left: -50px;

      & .icon {
        animation: parallax 4s linear infinite;
      }
    }

    &--2 {
      top  : 25%;
      right: -50px;

      & .icon {
        animation: lightning 3s linear infinite, events 5s linear infinite;
      }
    }
  }
}